import "./src/styles/global.scss";
import { availableLanguages, defaultLanguage } from "./src/utils";

export const onPreRouteUpdate = async ({ location }) => {
  const pathParts = location.pathname.split("/").filter(Boolean);

  if (pathParts.includes("news")) {
    const language = availableLanguages.includes(pathParts[0])
      ? pathParts[0]
      : defaultLanguage;
    window.localStorage.setItem("gatsby-i18next-language", language);
  }
};
