const isBrowser = typeof window !== "undefined";

const availableLanguages = ["fr", "it"];
const defaultLanguage = "en";

const baseSupportUrl = "https://raynfinance.zendesk.com/hc";

const supportUrl = {
  en: `${baseSupportUrl}/en-gb`,
  fr: `${baseSupportUrl}/fr`,
  it: `${baseSupportUrl}/it`,
};

const SPONTANEOUS_APPLY_SLUG = "0e5cx6qncz-candidature-spontanee";

const decodePhoneNumber = (hash) => {
  const rand = hash.slice(-3);
  const h = hash.slice(0, -3);

  const phone =
    (parseInt(h, 16) + parseInt(rand[2], 10)) / parseInt(rand[1], 10) -
    parseInt(rand[0], 10);

  return phone;
};

const encodePhoneNumber = (phoneNumber) => {
  const rand1 = Math.round(Math.random() * (9 - 1) + 1);
  const rand2 = Math.round(Math.random() * (9 - 1) + 1);
  const rand3 = Math.round(Math.random() * (9 - 1) + 1);
  const b = (parseInt(phoneNumber, 10) + rand1) * rand2 - rand3;
  const hash = `${b.toString(16)}${rand1}${rand2}${rand3}`;

  return hash;
};

const transformRange = (value, oldRange, newRange) => {
  if (value < oldRange[0]) {
    return newRange[0];
  }
  if (value > oldRange[1]) {
    return newRange[1];
  }

  const newValue =
    ((value - oldRange[0]) / (oldRange[1] - oldRange[0])) *
      (newRange[1] - newRange[0]) +
    newRange[0];

  return newValue;
};

const getChildrenNode = (base, node) =>
  node.reduce(
    (acc, el) =>
      el.type === "text"
        ? el.value.trim()
          ? `${acc ? `${acc} ${el.value.trim()}` : el.value.trim()}`
          : acc
        : getChildrenNode(acc, el.children),
    base
  );

const getTextFromHtmlAst = (ast) => getChildrenNode("", ast.children);

const stripHtmlTags = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

module.exports = {
  SPONTANEOUS_APPLY_SLUG,
  availableLanguages,
  defaultLanguage,
  encodePhoneNumber,
  getTextFromHtmlAst,
  isBrowser,
  stripHtmlTags,
  supportUrl,
  transformRange,
};
